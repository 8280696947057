<template>
  <div class="d-flex justify-content-center">
    <b-row :class="`mt-3 ${has_slug ? 'col-12 col-sm-10' : 'col-12 col-lg-8 col-xl-5'}`">
      <b-col class="col-12 text-center">
        <h1 class="mb-1"> {{ $t('creator.locationInfluencers') }}</h1>
        <p class="mb-3">{{ $t('creator.locationInfluencersText') }}</p>
        
        <b-form-radio-group class="cursor-pointer" v-model="type_location">
          <b-form-radio class="w-100 text-left mb-1" value="any">
            <strong>{{ $t('creator.everybody') }}</strong>
            <p class="avenir-medium">
              {{ $t('creator.everybodyText') }}
            </p>
          </b-form-radio>
          <b-form-radio class="w-100 text-left" value="specific">
            <strong>{{ $t('creator.locationSpecific') }}</strong>
            <p class="avenir-medium">{{ $t('creator.locationSpecificText') }}</p>
          </b-form-radio>
        </b-form-radio-group>
        <div class="container-audience-location-filter" v-if="type_location === 'specific'">
          <audience-location-filter
            :location="location"
            :index="0"
            :main_locations_filter="main_locations_filter"
            :show_slider="false"
            @changeLocationAudienceChild="changeLocationAudience"
            @removeAudienceLocationChild="removeAudienceLocation"
            class="w-100"
          />
        </div>
        <b-col class="col-12 d-flex justify-content-center">
          <b-button :disabled="type_location !== 'any' && Object.keys(location_selected).length === 0" variant="blue-button-next-step" class="blue-button-next-step my-2"  @click="save()">{{has_slug ? $t('creator.savePreview') : $t('creator.butonNext')}}</b-button>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormRadioGroup,
  BFormRadio,
} from 'bootstrap-vue';
import casting_calls_services from '@/services/casting_calls';
import services_search from '@/services/search';

export default {
  name: 'stepLocation',
  components: {
    BRow,
    BCol,
    BButton,
    BFormRadioGroup,
    BFormRadio,
    AudienceLocationFilter: () => import('@/views/buscador/filters/audience_locations.vue'),
  },
  data() {
    return {
      type_location: 'any',
      main_locations_filter: [],
      steps: null,
      casting_call: {},
      casting_call_uuid: '',
      location_selected: {},
      location: {}
    }
  },
  created() {
    this.getData();
    
  },
  computed: {
    has_slug() {
      return this.$route.params.slug
    }
  },
  methods: {
    changeLocationAudience(data) {
      this.location_selected = data.location;
    },
    removeAudienceLocation() {
      this.location_selected = {}
    },
    getMainLocationsForFilter() {
      services_search.getMainLocations().then((response) => {
        this.main_locations_filter = response.countries;
      })
    },
    getData() {
      this.getMainLocationsForFilter();
      if (this.has_slug) {
        this.casting_call = {...this.$route.params.casting_call_data}
        this.casting_call_uuid = this.casting_call.uuid;
        this.assignData();
      } else {
        this.steps = JSON.parse(localStorage.getItem('steps'));
        this.casting_call_uuid = this.steps.uuid ? this.steps.uuid : this.$route.params.casting_call_uuid
        casting_calls_services.getCastingCall(this.casting_call_uuid).then((response) => {
          this.casting_call = response
          this.assignData();
        });
      }
    },
    assignData() {
      if (this.casting_call.location.length > 0) {
        this.type_location = 'specific'
        this.location.id = 1;
        this.location.name = this.casting_call.location;
        this.location_selected.name = this.casting_call.location;
      } else this.type_location = 'any'
    },
    save() {
      const form_data = new FormData();
      form_data.append('location', this.type_location === 'any' ? '' : this.location_selected.name)
      casting_calls_services.updateCastingCall(this.casting_call_uuid, form_data).then((response) => {
        if (response.status === 'OK' && !this.has_slug) this.$router.push({ name: 'steps_casting_call', params: {step: 'delivery-deadline'}})
        else this.$emit('update_campaign', response.response);
      })
    },
  },
}
</script>
<style scoped>
.container-audience-location-filter {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}
.avatar-middle-stepper {
  position: absolute;
  top: 7em;
  z-index: 10000;
  left: 0;
  right: 0;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
</style>